// view google analytics (GA4) ecommerce data, and migration instructions here:
// https://support.google.com/analytics/answer/10119380?hl=en

import {SocialAuthProvider} from '@stryd/models';

import {
  HOME_PAGE_AB_VERSION,
  STORE_HERO_DEFAULT_OPTION,
  STORE_HERO_DEFAULT_OPTION_2,
} from 'src/components/experiment/experiments';

export type Event<
  Name extends string = string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  Params extends Record<string, unknown> = {}
> = {
  event: Name;
  client_id?: string;
} & Params;

export type EcommerceEvent<
  Name extends string = string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  EcommerceParams extends Record<string, unknown> = {},
  // eslint-disable-next-line @typescript-eslint/ban-types
  EventParams extends Record<string, unknown> = {}
> = Event<Name, EventParams> & {ecommerce: EcommerceParams | null};

export type EcommercePurchaseEvent<
  Name extends string = string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  EcommerceParams extends Record<string, unknown> = {},
  // eslint-disable-next-line @typescript-eslint/ban-types
  EventParams extends Record<string, unknown> = {},
  // eslint-disable-next-line @typescript-eslint/ban-types
  UserDataParams extends Record<string, unknown> = {}
> = Event<Name, EventParams> & {ecommerce: EcommerceParams | null} & {
  user_data: UserDataParams | null;
} & ExtraCommerceData;

export const isEcommerceEvent = <EventType extends EcommerceEvent>(
  event: Event
): event is EventType => {
  return 'ecommerce' in event;
};

type EventParams<T extends Event> = Omit<T, 'event'>;

export type TestEvent = Event<'test_event'>;

type PageViewEvent = Event<'page_view', {url: string}>;

export interface GaEcommerceItem {
  /** The stripe product name */
  item_name: string;
  /** The stripe product variant id */
  item_id: string;
}

type ItemDataEventParams = {
  items: GaEcommerceItem[];
  currency: string;
  value: number;
};

type ItemList = {
  item_list_name: string;
  item_list_id: string;
};

interface ExtraCommerceData {
  includesSubscription?: boolean;
  includesGift?: boolean;
}

type AddToCartEvent = EcommerceEvent<'add_to_cart', ItemDataEventParams> &
  ExtraCommerceData;

type CheckoutEventParams = ItemDataEventParams & {coupon: string};

type BeginCheckoutEvent = EcommerceEvent<
  'begin_checkout',
  CheckoutEventParams
> &
  ExtraCommerceData;

type PurchaseEcommerceEventParams = ItemDataEventParams & {
  transaction_id: string;
  /** The name or code of the affiliate (partner/vendor; if any) associated with a purchase */
  affiliation?: string;
  /** The coupon code applied */
  coupon?: string;
  /** The total cost of the shipping */
  shipping?: number;
  /** The value of the tax applied */
  tax?: number;
};

type UserDataParam = {
  email_address: string;
  city: string;
  region: string;
  postal_code: string;
  country: string;
};

type VariationParam = {
  /** Any experiment variation that the purchase was part of */
  variation?: string;
};

type PurchaseEvent = EcommercePurchaseEvent<
  'purchase',
  PurchaseEcommerceEventParams,
  VariationParam,
  UserDataParam
>;

type ViewItemEvent = EcommerceEvent<'view_item', ItemDataEventParams>;

type ViewItemListEvent = EcommerceEvent<
  'view_item_list',
  Pick<ItemDataEventParams, 'items'> & ItemList
>;

type SelectItemEvent = EcommerceEvent<'select_item', ItemDataEventParams> &
  ItemList;

type ViewExperimentVariationEventParams = {
  experimentId: string;
  variation: string;
  experimentName?: string;
};

type ViewExperimentEvent = Event<
  'view_experiment_variation',
  ViewExperimentVariationEventParams
>;

type AuthEventParams = {
  method: SocialAuthProvider | 'email';
};

type LoginEvent = Event<'login', AuthEventParams>;

type SignUpEvent = Event<'sign_up', AuthEventParams>;

type GenericSignUpErrorEvent = Event<'sign_up_error'>;

type AccountExistsErrorEvent = Event<'account_exists_error'>;

type SignupForNewsletterEvent = Event<
  'signup_for_newsletter',
  {listName: string}
>;

type CoachFilterEvent = Event<'coach_filter', {[key: string]: any}>;

export const testEvent = (): TestEvent => {
  return {
    event: 'test_event',
  };
};

export const pageViewEvent = (
  params: EventParams<PageViewEvent>
): PageViewEvent => {
  return {
    event: 'page_view',
    ...params,
  };
};

export const addToCartEvent = (
  params: EventParams<AddToCartEvent>
): AddToCartEvent => {
  return {
    event: 'add_to_cart',
    ...params,
  };
};

export const beginCheckoutEvent = (
  params: EventParams<BeginCheckoutEvent>
): BeginCheckoutEvent => {
  return {
    event: 'begin_checkout',
    ...params,
  };
};

export const completePurchaseEvent = (
  params: EventParams<PurchaseEvent>
): PurchaseEvent => {
  return {
    event: 'purchase',
    ...params,
  };
};

export const viewItemEvent = (
  params: EventParams<ViewItemEvent>
): ViewItemEvent => {
  return {
    event: 'view_item',
    ...params,
  };
};

export const viewItemListEvent = (
  params: EventParams<ViewItemListEvent>
): ViewItemListEvent => {
  return {
    event: 'view_item_list',
    ...params,
  };
};

export const selectItemEvent = (
  params: EventParams<SelectItemEvent>
): SelectItemEvent => {
  return {
    event: 'select_item',
    ...params,
  };
};

export const viewExperimentVariationEvent = (
  params: EventParams<ViewExperimentEvent>
): ViewExperimentEvent => {
  return {
    event: 'view_experiment_variation',
    ...params,
  };
};

export const loginEvent = (params: EventParams<LoginEvent>): LoginEvent => {
  return {
    event: 'login',
    ...params,
  };
};

export const signUpEvent = (params: EventParams<SignUpEvent>): SignUpEvent => {
  return {
    event: 'sign_up',
    ...params,
  };
};

export const genericSignUpErrorEvent = (): GenericSignUpErrorEvent => {
  return {
    event: 'sign_up_error',
  };
};

export const accountExistsErrorEvent = (): AccountExistsErrorEvent => {
  return {
    event: 'account_exists_error',
  };
};

export const signupForNewsletter = (
  params: EventParams<SignupForNewsletterEvent>
): SignupForNewsletterEvent => {
  return {
    event: 'signup_for_newsletter',
    ...params,
  };
};

export const coachFilterEvent = (
  params: EventParams<CoachFilterEvent>
): CoachFilterEvent => {
  return {
    event: 'coach_filter',
    ...params,
  };
};

/**
 * @deprecated
 */
type ViewStoreHeroEvent = Event<
  'view_store_hero',
  {
    [HOME_PAGE_AB_VERSION]?: string;
    [STORE_HERO_DEFAULT_OPTION]?: string;
    [STORE_HERO_DEFAULT_OPTION_2]?: string;
  }
>;

/**
 * @deprecated
 */
export const viewStoreHero = (
  params: EventParams<ViewStoreHeroEvent>
): ViewStoreHeroEvent => ({
  event: 'view_store_hero',
  ...params,
});
